<template>
    <div>
        <Dialog v-model:visible="isModalVisible" :style="{ width: '400px' }" :header="'Send business goal email'" :modal="true"
        @update:visible="hideDialog" @hide="hideDialog" class="p-fluid p-input-filled">
            <div class="grid">
                <div class="col-12">
                    <div class="field">
                        <label for="displayName">To</label>
                        <InputText id="displayName" v-model="to" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="field">
                        <label for="title">Title</label>
                        <InputText id="title" v-model="title" />
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="c-dialog__footer">
                    <div class="c-dialog__footer--end">
                        <Button label="Send" icon="pi pi-check" class="p-button" @click="sendEmail" :disabled="isSubmitted" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script lang="ts">
import { useBusinessGoalApi } from '@/composables/api/useBusinessGoalApi';
import { SendRecommendationEmailRequest } from '@/api/contracts/models';
import { useParams } from '@/composables/utils/useParams';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
    name: 'RecommendationEmailModal',

    components: {
        Button,
        InputText,
        Dialog,
    },

    props: {
        recommendationId: String,
        isVisible: Boolean
    },

    setup(props, context) {
        const hideDialog = () => {
            context.emit('hideDialog')
        }

        const isModalVisible = ref(props.isVisible)
        const isSubmitted = ref(false)

        const to = ref('')
        const title = ref('')

        watch(
            () => props.isVisible,
            () => {
                isModalVisible.value = props.isVisible,
                isSubmitted.value = false
                to.value = ''
                title.value = ''
            },
            {
                immediate: true
            }
        )

        const { customerId } = useParams()

        const businessGoalApi = useBusinessGoalApi()

        const sendEmail = () => {
            isSubmitted.value = true

            businessGoalApi.sendEmail(
                { 
                    customerId: customerId.value!,
                    title: title.value,
                    to: to.value
                } as SendRecommendationEmailRequest
            ).then(result => hideDialog(), result => console.log(result))
        }

        return {
            hideDialog,
            sendEmail,
            to,
            isModalVisible,
            isSubmitted,
            title        
        }
    }
})
</script>